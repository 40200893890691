<template>
  <div>
    <b-row>
      <b-col>
        <p>
          Para abrir um ticket, entre no portal de atendimento no endereço
          <b-link
            class="hyperlink"
            href="https://app.tiflux.com"
            target="_blank"
          >
            https://app.tiflux.com</b-link>
          e clique no botão "Abrir novo ticket".
        </p>
        <p>Utilize o login e senha recebidos no seu e-mail echope.</p>
        <p>
          Caso não tenha recebido o convite para acesso ao portal, entrar em contato com o(a) Head
          da sua loja.
        </p>
      </b-col>
    </b-row>
    <br>
    <b-row>
      <b-col>
        <p>
          Ainda com dúvidas de como prosseguir com a abertura do ticket ? Abaixo um manual para
          melhor orientação.
        </p>
        <section style="width: 100%; height: 79vh">
          <iframe
            style="width: 100%; height: 100%; border: 0; background-color: white"
            src="https://docs.google.com/document/d/e/2PACX-1vSefyIuVOTqcZRpyxvnxW-SIas0rU-1JPxlbWysYp3kBuw2jujF742NM3XokMU-LdhXX71UPHUy6yws/pub?embedded=true"
          />
        </section>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import { BLink, BRow, BCol } from 'bootstrap-vue'

export default {
  components: {
    BLink,
    BRow,
    BCol,
  },
  data() {
    return {
      supportEmail: 'suporte.erp@echope.com.br',
    }
  },
}
</script>

<style></style>
