var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "b-row",
        [
          _c("b-col", [
            _c(
              "p",
              [
                _vm._v(
                  " Para abrir um ticket, entre no portal de atendimento no endereço "
                ),
                _c(
                  "b-link",
                  {
                    staticClass: "hyperlink",
                    attrs: { href: "https://app.tiflux.com", target: "_blank" },
                  },
                  [_vm._v(" https://app.tiflux.com")]
                ),
                _vm._v(' e clique no botão "Abrir novo ticket". '),
              ],
              1
            ),
            _c("p", [
              _vm._v("Utilize o login e senha recebidos no seu e-mail echope."),
            ]),
            _c("p", [
              _vm._v(
                " Caso não tenha recebido o convite para acesso ao portal, entrar em contato com o(a) Head da sua loja. "
              ),
            ]),
          ]),
        ],
        1
      ),
      _c("br"),
      _c(
        "b-row",
        [
          _c("b-col", [
            _c("p", [
              _vm._v(
                " Ainda com dúvidas de como prosseguir com a abertura do ticket ? Abaixo um manual para melhor orientação. "
              ),
            ]),
            _c("section", { staticStyle: { width: "100%", height: "79vh" } }, [
              _c("iframe", {
                staticStyle: {
                  width: "100%",
                  height: "100%",
                  border: "0",
                  "background-color": "white",
                },
                attrs: {
                  src: "https://docs.google.com/document/d/e/2PACX-1vSefyIuVOTqcZRpyxvnxW-SIas0rU-1JPxlbWysYp3kBuw2jujF742NM3XokMU-LdhXX71UPHUy6yws/pub?embedded=true",
                },
              }),
            ]),
          ]),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }